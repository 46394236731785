<!--<template>
	<div class="pdf-box">
		<div class="header ellipsis_one">
			<a @click="goback"> <img src="../assets/images/back.png" alt=""></a><span>{{ titleName }}</span></div>
		<pdf v-for="i in numPages" :key="i" :src="pdfSrc" :page="i">
		</pdf>
	</div>
</template>-->

<template>
	<div class="pdf container" v-show="fileType === 'pdf'">
		<div class="header ellipsis_one">
			<a @click="goback"> <img src="../assets/images/back.png" alt=""></a><span>{{ titleName }}</span></div>
		<p class="arrow">
			<!-- padding-left: 15%; -->
			<!-- // 上一页 -->
			<span @click="changePdfPage(0)" class="turn previous_page" :class="{grey: currentPage==1}">上一页</span> {{currentPage}} / {{pageCount}}
			<!-- // 下一页 -->
			<span @click="changePdfPage(1)" class="turn" :class="{grey: currentPage==pageCount}">下一页</span>
			<img  v-if="ifShowD" class="enlarge" :class="{select:idx==0}" @touchstart="idx=0" @touchend="idx=-1" @click="scaleD" src="../assets/images/zoom-out.png" />
			<img v-if="ifShowX" class="narrow" :class="{select:idx==1}" @touchstart="idx=1" @touchend="idx=-1" @click="scaleX" src="../assets/images/zoom-in.png" />
		<!-- style="display: none;"  -->
		</p>
		<!-- <img style="width: 20px;" :class="{select:idx==0}" @touchstart="idx=0" @touchend="idx=-1" @click="scaleD" src="../assets/images/zoom-out.png" /> -->
		<!-- <div class="enlarge" style="margin-top: 84px;">
			<button :class="{select:idx==0}"
			        @touchstart="idx=0"
			        @touchend="idx=-1"
			        @click="scaleD">
				放大
			</button>
			<button style="display: none;" :class="{select:idx==1}"
			        @touchstart="idx=1"
			        @touchend="idx=-1"
			        @click="scaleX">
				<img src="../assets/images/zoom-in.png" />
			</button>
		</div> -->
		<div style="height: 5rem;background-color: #fff;width: 100%;max-width: 620px; "></div>
		<!-- // 自己引入就可以使用,这里我的需求是做了分页功能,如果不需要分页功能,只要src就可以了 -->
		<pdf ref="pdf" class="pdfs" :src="pdfSrc" :page="currentPage" @num-pages="pageCount=$event" @page-loaded="currentPage=$event"
		 @loaded="loadPdfHandler">
		</pdf>
		<!-- <div style="height: 3rem;background-color: #fff;"></div> -->
	</div>
</template>
<script>
	//	import pdf from 'vue-pdf'
	//	export default {
	//		metaInfo: {
	//			meta: [{
	//				name: 'viewport',
	//				content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=2,user-scalable=yes'
	//			}]
	//		},
	//		components: {
	//			pdf
	//		},
	//		data() {
	//			return {
	//				titleName: '',
	//				pdfSrc: '',
	//				//    pdfSrc:"https://dakaname.oss-cn-hangzhou.aliyuncs.com/file/2018-12-28/1546003237411.pdf",
	//				numPages: 0,
	//			}
	//		},
	//		methods: {
	//			goback: function() {
	//				this.$router.go(-1)
	//			},
	//		},
	//		mounted() {
	//			
	//		},
	//		created(){
	//			this.titleName = this.$route.query.titleName;
	//			document.title = this.$route.query.titleName;
	//			// 有时PDF文件地址会出现跨域的情况,这里最好处理一下
	//			this.pdfSrc = pdf.createLoadingTask(this.$route.query.path)
	//			this.pdfSrc.then(pdf => {
	//				console.log('---',pdf.numPages)
	//				this.numPages = pdf.numPages
	//			})
	//		}
	//	}

	import pdf from 'vue-pdf'
	export default {
		metaInfo: {
			titleName: '',
			title: 'This is the test',
			meta: [{
					charset: 'utf-8'
				},
				{
					name: 'viewport',
					content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=2,user-scalable=yes'
				}
			]
		},
		components: {
			pdf
		},
		// props: ['pdfSrc'],
		data() {
			return {
				currentPage: 0, // pdf文件页码
				pageCount: 0, // pdf文件总页数
				fileType: 'pdf', // 文件类型
				pdfSrc: this.$route.query.path ,// pdf文件地址
				// pdfSrc: "https://dakaname.oss-cn-hangzhou.aliyuncs.com/file/2018-12-28/1546003237411.pdf",

				scale: 100, //放大系数
				idx: -1,
				
				// 放大按钮 缩小按钮
				ifShowD:true,
				ifShowX:false
			}
		},
		created() {
			// 有时PDF文件地址会出现跨域的情况,这里最好处理一下
			this.titleName = this.$route.query.titleName;
			document.title = this.$route.query.titleName;
			this.pdfSrc = pdf.createLoadingTask(this.pdfSrc)
		},
		methods: {
			goback: function() {
				this.$router.go(-1)
			},
			// 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
			changePdfPage(val) {
				if (val === 0 && this.currentPage > 1) {
					this.currentPage--
				}
				if (val === 1 && this.currentPage < this.pageCount) {
					this.currentPage++
				}
			},

			//放大
			scaleD() {
				if (this.scale == 200) {
					// 隐藏放大 显示缩小
					this.ifShowD = false
					this.ifShowX = true
					return;
				}
				this.scale += 5;
				this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
			},

			//缩小
			scaleX() {
				if (this.scale == 100) {
					// 显示放大 隐藏缩小
					this.ifShowX = false
					this.ifShowD = true
					return;
				}
				this.scale += -5;
				this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
			},

			// pdf加载时
			loadPdfHandler(e) {
				this.currentPage = 1 // 加载的时候先加载第一页
			}
		}
	}
</script>
<style>
	.pdf {
		background-color: #fff;
	}

	.arrow {
		/* ❀ */
		/* padding-top: 2.4rem; */
		padding-bottom: 0.6rem;
		background-color: #fff;
		text-align: center;
		font-size: 0.9rem;
		position: fixed;
		z-index: 100;
		/* left: 6rem; */
		top: 2rem;
		width: 100%;
		max-width: 620px; 
	}

	.pdfs {
		height: calc(100vh - 3rem);
	}

	.pdfs canvas {
		height: 100%;
	}
	
	.previous_page{
		padding-left: 15%;
	}
	
	.enlarge{
		width: 20px; 
		margin-right: 2.2rem;
		float: right;
		padding-top: 0.2rem;
	}
	
	.narrow{
		width: 20px;
		margin-right: 2.2rem;
		float: right;
		padding-top: 0.2rem;
	}
	/* .enlarge button{
		background-color: #fff;
	}
	.enlarge img{
		width: 20px;
	} */
</style>
