<template>
	<div>
		<div class="videomain">
			<div class="header videoback ellipsis_one" @click="goback"><img src="../assets/images/back.png" alt=""><span style="width: 80%;display:block;vertical-align: middle;margin: 0 auto;">{{ titleName }}</span></div>
		</div>
		<div v-html="cont" style="margin-top: 2.2rem;" class="ueInfo ql-editor"></div>
	</div>
</template>
<script>
	import { getResourceContents } from "@/assets/js/api";
	export default {
		name: "unitsummaryvideo",
		data() {
			return {
				cont: '',
				titleName: ''
			};
		},
		methods: {
			goback: function() {
				this.$router.go(-1)
			},
			getTuInfo() {
				getResourceContents({
					resourceId: this.$route.query.resourceId,
				}).then(res => {
					if(res.code == 0) {
						console.log(res)
						this.cont = res.resourceContent.resourceContent
					}
				});
			}
		},
		mounted() {
			this.getTuInfo();
			this.titleName = this.$route.query.titleName;
			document.title = this.$route.query.titleName;
		}
	};
</script>
<style>
	.ueInfo {
		padding: 0.5rem;
		word-wrap: break-word;
		word-break: normal;
	}
	
	.ueInfo img {
		max-width: 100%;
		height: auto;
	}
</style>