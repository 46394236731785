<template>
	<div class="pdf-box">
		<div class="header ellipsis_one">
			<a @click="goback"> <img src="../assets/images/back.png" alt=""></a><span>{{ titleName }}</span></div>
		<iframe frameborder="0"
                :src="'https://view.officeapps.live.com/op/view.aspx?src=' + this.$route.query.path"
                width='100%'>
        </iframe>
	</div>
</template>
<script>
	export default {
		metaInfo: {
			meta: [{
				name: 'viewport',
				content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=2,user-scalable=yes'
			}]
		},
		data() {
			return {
				titleName: '',
				pdfSrc: this.$route.query.path,
				//    pdfSrc:"https://dakaname.oss-cn-hangzhou.aliyuncs.com/file/2018-12-28/1546003237411.pdf",
				numPages: undefined,
			}
		},
		methods: {
			goback: function() {
				this.$router.go(-1)
			},
		},
		mounted() {
			this.titleName = this.$route.query.titleName;
			document.title = this.$route.query.titleName;
		}
	}
</script>