<template>
	<div class="fixedWrapper" style="height: 100vh;background-color: #fff;">
		<div class="videomain" style="width: 100%;">
			<div class="header" @click="goback"><img src="../assets/images/back.png" alt="">音频</div>
			<div style="padding-top: 2.2rem;">
				<img src="" />
				<div class="audioInfo">
					<img :src="bookPic" />
					<div>{{ nowPlayInfo.resourceName }}</div>
				</div>
				<div class="progress">
					<mt-progress :value="nowTimeLength" :bar-height="5"></mt-progress>
					<div class="f-n">
						<span class="f-l">{{initAudio.currentTime | formatDate}}</span>
						<span class="f-r">{{initAudio.audioLength | formatDate}}</span>
					</div>
					<div class="audioOption">
						<div>
							<img src="../assets/images/ht.png" @click="back" />
						</div>
						<div>
							<img src="../assets/images/prev.png" @click="prev" />
						</div>
						<div  v-if="initAudio.play" style="height: 2rem;margin-top: -0.3rem;">
							<img src="../assets/images/play3.png"  @click="playAudio" style="width: 2rem;height: 2rem;"/>
						</div>
						<div v-else style="height: 2rem;margin-top: -0.3rem;">
							<img src="../assets/images/play.png" @click="playAudio" style="width: 2rem;height: 2rem;"/>
						</div>
						<div>
							<img src="../assets/images/next.png" @click="next"/>
						</div>
						<div>
							<img src="../assets/images/kj.png" @click="fast"/>
						</div>
					</div>
				</div>
				<audio style="display: none;" ref="audio" @ended="ended" preload="auto" @pause="handPlay(2)" @timeupdate="audioTimeUpdate" @play="handPlay(1)" @loadedmetadata="getAudioLength" autoplay :src="nowPlayInfo.resourcePath" controls="controls"></audio>
			</div>
		</div>
		<!--<div style="height:2.2rem;"></div>-->
		<div class="bottomOption">
						<div>
							<a :href="nowPlayInfo.resourcePath" :download="nowPlayInfo.resourceName">
								<img src="../assets/images/down_big.png" />
							</a>
							
						</div>
						<div v-if="initAudio.isSignle">
							<img src="../assets/images/cycle.png" @click="isSingletEven" />
						</div>
						<div v-else>
							<img src="../assets/images/l.png" style="height: 1.2rem;width: 1.2rem;margin-top: 0.2rem;" @click="isSingletEven" />
						</div>
						<div>
							<img src="../assets/images/speed.png" v-if="initAudio.defaultPlaybackRate==1" @click="double" />
							<img src="../assets/images/speed2.png" v-else @click="double" />
						</div>
						<!--<div v-if="initAudio.isSignle">
							<img src="../assets/images/db.png" @click="isSingletEven" />
						</div>
						<div v-else>
							<img src="../assets/images/liastB.png" @click="isSingletEven" />
						</div>
						<div>
							<img src="../assets/images/list.png" @click="showAudioList" />
						</div>-->
					</div>
		<mt-popup v-model="popupVisible" position="right">
		</mt-popup>
	</div>
</template>
<script>
	import { listResource } from "@/assets/js/api";
	import { Progress, Popup } from 'mint-ui';
	import dateFormat from '@/assets/js/date'
	export default {
		name: "unitsummaryvideo",
		data() {
			return {
				bookPic:'',
				popupVisible: false,
				nowTimeLength: 0, //音频的进度条比例
				nowPlayInfo: {},
				tabIndex: 1,
				unitsummaryArr: [],
				doubleArr: [1, 1.2, 1.5, 2.0],
				initAudio: {
					play: false, //播放还是暂停 true播放中
					audioLength: 0, //audio时长
					url: "", //音频课件url
					currentTime: 0, //当前播放时间
					lastTime: null, //标记时间戳
					defaultPlaybackRate: 1, //播放速度
					isSignle:false,
				},
			};
		},
		computed: {
			evenNumbers: function() {
				let that = this;
				return this.unitsummaryArr.filter(function(item) {
					return item.resourceType == 2
				})
			}
		},
		filters: {
			formatDate(time) {
				return dateFormat.formatDate(time, "hh:mm:ss");
			}
		},
		methods: {
			goback: function() {
				this.$router.go(-1)
			},
			showAudioList() {
				this.popupVisible = true;
			},
			//播放完毕的方法
			ended(){
				if(!this.initAudio.isSignle){
//					this.next();//下一首
				}else {
					this.$refs.audio.play();
				}
			},
			//播放音频
			playAudio() {
				if(this.initAudio.play) {
					this.$refs.audio.pause(); //暂停
				} else {
					this.$refs.audio.play();
					this.$refs.audio.muted = false
				}
			},
			//单曲循环切换
			isSingletEven(){
				this.initAudio.isSignle = !this.initAudio.isSignle;
			},
			//上一首
			prev(){
				for(var i = 0;i<this.evenNumbers.length;i++){
					if(this.nowPlayInfo.resourceId== this.evenNumbers[i].resourceId){
						if(i == 0){
							this.nowPlayInfo = this.evenNumbers[this.evenNumbers.length-1];
							this.$refs.audio.play();
							return
						}else if(i>=this.evenNumbers.length) {
							this.nowPlayInfo = this.evenNumbers[0];
							this.$refs.audio.play();
							return
						}else {
							this.nowPlayInfo = this.evenNumbers[i-1];
							this.$refs.audio.play();
							return
						}
					}
				}
			},
			//下一首
			next(){
				for(var i = 0;i<this.evenNumbers.length;i++){
					if(this.nowPlayInfo.resourceId== this.evenNumbers[i].resourceId){
						if(i == 0){
							this.nowPlayInfo = this.evenNumbers[i+1];
							this.$refs.audio.play();
							return
						}else if(i==this.evenNumbers.length-1) {
							this.nowPlayInfo = this.evenNumbers[0];
							this.$refs.audio.play();
							return
						}else {
							this.nowPlayInfo = this.evenNumbers[i+1];
							this.$refs.audio.play();
							return
						}
					}
				}
			},
			//倍速播放
			double() {
				if(this.$refs.audio.playbackRate==1){
					this.$refs.audio.playbackRate = 2;
					this.initAudio.defaultPlaybackRate = 2;
				}else {
					this.$refs.audio.playbackRate = 1;
					this.initAudio.defaultPlaybackRate = 1;
				}
				console.log(this.initAudio)
			},
			//快进
			fast() {
				this.$refs.audio.currentTime = this.$refs.audio.currentTime + 15;
			},
			//后退
			back() {
				this.$refs.audio.currentTime = this.$refs.audio.currentTime - 15;
			},
			//获取音频信息
			getAudioLength() {
				this.initAudio.audioLength = this.$refs.audio.duration
			},
			handPlay(status) { //音视频播放暂停 type:1 音频 2视频 status 1播放 2暂停
				status == 1 ? this.initAudio.play = true : this.initAudio.play = false;
			},
			audioTimeUpdate() { //更新音频时间。节流，每秒触发一次
				let nowTime = Date.now()
				let gapTime = 1000;
				if(!this.initAudio.lastTime || nowTime - this.initAudio.lastTime > gapTime) {
					if(this.$refs.audio) {
						let time = this.$refs.audio.currentTime;
						this.initAudio.currentTime = time;
						this.nowTimeLength = (time / this.initAudio.audioLength) * 100
					}
					this.initAudio.lastTime = nowTime
				}
			},
			//获取单元信息
			getlistResource() {
				let that = this;
				listResource({
					chapterId: this.$route.query.chapId,
				}).then(res => {
					if(res.code == 0) {
						this.unitsummaryArr = res.resourceList;
						for(var i = 0; i < res.resourceList.length; i++) {
							if(res.resourceList[i].resourceId == this.$route.query.url) {
								this.nowPlayInfo = res.resourceList[i]
							}
						}
						 document.title = this.nowPlayInfo.resourceName;
						 this.bookPic = res.chapterInfo.bookPic;
					}
				});
			},

		},
		mounted() {
			this.audioInfo = this.$route.query.url;
			this.getlistResource()
		}
	};
</script>