<template>
	<div>
		<div class="videomain">
			<div class="header videoback" @click="goback"><img src="../assets/images/back.png" alt="">视频</div>
			<div style="margin-top: 2.2rem;">
				<video id="myVideo" ref="videoPlayer" class="video-js vjs-default-skin vjs-big-play-centered">
					<source src="" type="video/mp4" >
				</video>
			</div>

			<div class="title" @click="loadVideo">
				<h3>{{ nowPlayInfo.resourceName }}</h3>
				<span style="float: right;font-size: 0.4rem;">下载</span>
				<img src="../assets/images/down_big.png" alt="" >
			    
				
			</div>
		</div>
		<div class="container" style="padding: 0;">
			<div class="unitsummary-list">
				<div class="list flex_outside" :class="nowPlayInfo.resourceId == item.resourceId ? 'active': ''" v-for="(item,index) in evenNumbers" :key="index" @click="playVideo(item)">
					<div class="left">
						<img src="../assets/images/u1.png" alt="">
					</div>
					<div class="right flex_inside">
						<h3 class="ellipsis_two">{{item.resourceName}}</h3>
						<div class="us-bottom">
							<span v-if="item.resourceTime">
							  <img src="../assets/images/time.png" alt="">
							  <em>{{item.resourceTime | formatDate}}</em>
							</span>
							<!-- <span v-else>
							  <em></em> 
							</span> -->
							<span>
								<img src="../assets/images/memory.png" alt=""><em>{{item.resourceSize}}M</em> 
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { listResource } from "@/assets/js/api";
	import dateFormat from '@/assets/js/date'
	export default {
		name: "unitsummaryvideo",
		data() {
			return {
				unitsummaryArr: [],
				nowPlayInfo: {},
				myPlayer:null,
				playTime:0
			};
		},
		computed: {
			evenNumbers: function() {
				let that = this;
				return this.unitsummaryArr.filter(function(item) {
					console.log(item)
					return item.resourceType == 1
				})
			}
		},
		filters: {
			formatDate(time) {
				return dateFormat.formatDate(time, "hh:mm:ss");
			}
		},
		methods: {
			goback: function() {
				this.$router.go(-1)
			},
			//点击播放某个视频
			playVideo(item){
				if(item.resourceId !=this.nowPlayInfo.resourceId){
					this.myPlayer.src(item.resourcePath);
		            this.myPlayer.load(item.resourcePath);
				    this.nowPlayInfo = item;
				}
			},
			//下载视频
			loadVideo(){
				var aEle = document.createElement("a");// 创建a标签
				aEle.download = this.nowPlayInfo.resourceName; // 设置下载文件的文件名
		        aEle.href = this.nowPlayInfo.resourcePath;// content为后台返回的下载地址
		        aEle.click();
			},

			initVideo() {
				let that = this;
				//初始化视频方法
				this.myPlayer = this.$video(myVideo, {
					autoplay: true,
					controls: true, // 是否显示控制条
//					poster: require('../assets/images/booksImg.jpg'), // 视频封面图地址
					playbackRates: [0.5, 1, 1.5, 2],
					bigPlayButton: true,
					textTrackDisplay: true,
					posterImage: true,
					errorDisplay: false,
					controlBar: false,
					ControlBar: {
						customControlSpacer: true,
					},
					sources: [{
				    src:that.nowPlayInfo.resourcePath ,
				    type: 'video/mp4'
				  }]
				});
			},
			//获取单元信息
			getlistResource() {
				let that = this;
				listResource({
					chapterId: this.$route.query.chapId,
				}).then(res => {
					if(res.code == 0) {
						this.unitsummaryArr = res.resourceList;
						for(var i = 0; i < res.resourceList.length; i++) {
							if(res.resourceList[i].resourceId == this.$route.query.url) {
								this.nowPlayInfo = res.resourceList[i]
							}
						}
					  document.title = this.nowPlayInfo.resourceName;
					  this.initVideo();
					}
				});
			},
		},
		mounted() {
			this.getlistResource();
		},
	};
</script>