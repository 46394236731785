var dateFormat = {
    padLeftZero: function (str) {
        return ('00' + str).substr(str.length)
    },
    formatDate: function (date, fmt) {
        let o = {
            'h+': Math.floor((date % 86400) / 3600),
            'm+': Math.floor(((date % 86400) % 3600) / 60),
            's+': Math.floor(((date % 86400) % 3600) % 60)
        }
        for (let k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                let str = o[k] + ''
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : this.padLeftZero(str))
            }
        }
        return fmt
    }

}


export default dateFormat;
