<template>
	<div class="container">
		<div class="header ellipsis_one" @click="goback"><img src="../assets/images/back.png" alt=""> <span>{{ nameTitle }}</span></div>
		<div class="blian22"></div>
		<!-- 调整顺序 -->
		<!-- <div class="container">
			<!--<div class="downcont-head">
				<img src="../assets/images/activity.png" alt="">
				<button @click="lookNow">在线预览</button>
			</div> -->
			<!--<div class="downcont-main">
				<h3>下载至手机</h3>
				<p>如果您是苹果手机请选择下载电脑操作</p>
				<button @click="uploadInfo">点击下载</button>
			</div>
		</div> -->
		<div class="container">
			<div class="downcont-main">
				<h3>方法一:发送至邮箱</h3>
				<p>邮箱中点击链接下载</p>
				<div class="dcm-http flex_outside">
					<!-- <span class="http ellipsis_one flex_inside">{{ testPaperPath }}</span> -->
					<input  class="mailbox" name="text" v-model="mail" />
					<span class="send" @click="sendOut">发送</span>
				</div>
			</div>
		</div>
		<div class="container mt10">
			<div class="downcont-main">
				<h3>方法二: 下载到电脑</h3>
				<div class="dcm-img">
					<img src="../assets/images/phone.png" alt="" class="imgleft">
					<img src="../assets/images/line.png" alt="" class="imgcent">
					<img src="../assets/images/computer.png" alt="" class="imgright">
				</div>
				<p>您可以在浏览器中打开以下网址，查看该资源</p>
				<div class="dcm-http flex_outside">
					<span class="http ellipsis_one flex_inside">{{ testPaperPath }}</span>
					<span class="copy " @click="doCopy">复制</span>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="downcont-main">
				<h3>方法三:下载至手机</h3>
				<p>如果您是苹果手机请选择下载电脑操作</p>
				<button @click="uploadInfo">点击下载</button>
			</div>
		</div>
		<div class="instructions">
			<h3>说明：</h3>
			<!-- <p>1.发送邮箱成功之后，若收件箱中没有，请在垃圾邮件查看。</p> -->
			<p>1.微信中如果点击【点击下载】按钮没有反应，请点击右上角菜单，使用手机浏览器打开。</p>
			<p>2.苹果手机由于系统限制无法下载到手机上，请按照【下载到电脑】的操作进行下载。</p>
		</div>
	</div>
</template>
<script>
	import { Toast } from 'mint-ui';
	import  Email  from "@/assets/js/smtp.js";
	import { Indicator } from 'mint-ui';
	// import { emails } from 'emailjs';
	export default {
		name: "downcont",
		data() {
			return {
				testPaperPath: "",
				nameTitle: "",
				mail:"",
				bookName:""
			};
		},
		methods: {
			//在线预览
			lookNow(){
				this.$router.push({
					path: './unitsummaryPdf',
					query: {
						path:this.testPaperPath,
						titleName:this.$route.query.testName
					}
				});
			},
			//复制
			doCopy() {
				let that = this;
				this.$copyText(encodeURI(this.testPaperPath)).then(function() {
					Toast({
						message: '复制成功',
						position: 'middle',
						duration: 1000
					});
				}, function() {
					Toast({
						message: '复制失败',
						position: 'middle',
						duration: 1000
					});
				})
			},
			//下载
			uploadInfo(){
				 var aEle = document.createElement("a");// 创建a标签
                 aEle.href = this.testPaperPath;// content为后台返回的下载地址
                 aEle.click()
			},
			// 发送
			sendOut(){
				// console.log(this.testPaperPath)
				var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
				if( this.mail == ""){
					Toast({
						message: '请填写邮箱',
						position: 'middle',
						duration: 1000
					});
				}else{
					if(reg.test(this.mail)){
						Indicator.open()
						Email.send({
						    Host : "smtp.163.com",
						    Username : this.UsernameEmail,
						    Password : this.PasswordEmail,
						    To : this.mail,
						    From : this.FromEmail,
						    Subject : "资料包下载链接",
						    Body : "您好请查看："+ this.bookName + "的下载链接  <a href='"+ this.testPaperPath +"'>点击下载</a>"
						}).then( message => {
							if(message == "OK"){
								Toast({
									message: '邮件发送成功',
									position: 'middle',
									duration: 1000
								});
							}else{
								Toast({
									message: '邮件发送失败',
									position: 'middle',
									duration: 1000
								});
							}
							Indicator.close()
						});
					}else{
						Toast({
							message: '邮箱格式错误,请重新填写',
							position: 'middle',
							duration: 1000
						});
					}
				}
				console.log(this.mail)
			},
			goback: function() {
				this.$router.go(-1)
			},
		},
		mounted() {
			this.nameTitle = this.$route.query.titleName;
			// console.log(this.nameTitle)
			/* 截取资源名称 */
			let nameZY = this.nameTitle
			nameZY = nameZY.split('.').slice(-9999,-1).join(".")
			this.bookName = nameZY
			this.testPaperPath = this.$route.query.path;
			// document.title = this.$route.query.lessonName;
		}
	};
</script>
<style>
	.mailbox{
		/* width: 72%; */
		width: 100%;
		/* margin-right: 0.5rem; */
		border:1px solid #DDDDDD;
		border-right: none;
		/* border-radius: 5px; */
		font-size: 0.7rem;
	}
	.send{
		background: #40A5FF;
		height: 1.8rem;
		line-height: 1.8rem;
		/* border-radius: 5px; */
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		width: 4rem;
		text-align: center;
		font-size: 0.7rem;
		color: #fff;
	}
	
</style>
